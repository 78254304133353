<template>
    <div class="classDetail">
        <div class="headBox">
            <div class="headTop">
                <div class="crumbs">
                    <i><img src="../assets/img/loginIcon/index-white-icon.png" alt=""></i>首页 ><span> 课程列表 ></span><span class="color"> 课程详情</span>
                </div>
                <div class="classBox">
                    <div class="videoBox">
                        <img :src="classText.logoUrl" alt="" v-if="classText.logoUrl != ''">
                        <img src="../assets/img/default_c.png" alt="" v-else>
                    </div>
                    <div class="describeBox">
                        <div class="title">
                            <p>{{classText.name}}</p>
                        </div>
                        <div class="people">
                            <i>
                                <img src="../assets/img/loginIcon/class-people-icon.png" alt="">
                            </i>
                            <p>{{classText.viewCount}}</p>
                        </div>
                        <div class="teacher">
                            <p>课程章节： {{classText.classHour}}</p>
                            <p>主讲老师： {{teacherInfo.name}}</p>
                        </div>
                        <div class="price">
                            课程价格：
                            <label v-if="classText.isPay == 0">
                                <span class="discount">
                                    免费
                                </span>
                            </label>
                            <label v-else>
                                <span class="discount">￥{{classText.price}}</span>
                                <label v-if="classText.originalPrice == null">
                                    <span class="orignPrice"></span>
                                </label>
                                <label v-else>
                                    <span class="orignPrice">￥{{classText.originalPrice}}</span>
                                </label>
                            </label>
                            
                        </div>
                        <div class="buyBox">
                            <div v-if="classText.isBuy == 0" class="flexBox">
                                <div class="addCart" @click="addCart(classText.id)">加入购物车</div>
                                <div class="buy" @click="buy(classText.id)">立即购买</div>
                            </div>
                            <div v-else class="flexBox">
                                <div class="infor">
                                    已购买
                                </div>
                            </div>
                            <div class="like">
                                <div class="nonelike" :class="{'islike':classText.isFavorite}" @click="dianzhan(classText)"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contentBox">
            <div class="detailBox">
                <div class="tabBox">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="课程目录" name="first" :key="'first'">
                            <!-- <tabOne :headerData="headerData"></tabOne> -->
                            <div class="listBox">
                                <ul class="listUL">
                                    <li class="listLi" v-for="(itemOne,i) in lessonList" :key="i">
                                        <div>
                                            <div class="listSlide" @click="checkItem(i,itemOne)">
                                                <div class="book-icon">
                                                    <img src="../assets/img/loginIcon/class-icon.png" alt="">
                                                </div>
                                                <p class="topText">{{itemOne.title}}</p>
                                                <div class="arrow-down-icon" :class="{'arrow-up-icon':itemOne.group}" v-show="itemOne.list"></div>
                                            </div>
                                            <div class="slideBox" v-show="isOpenItem[i] && isOpenItem[i].group">
                                                <ul class="slideUl">
                                                    <!-- :data-length-n="itemOne.list.length" -->
                                                    <li class="slideLi" v-for="(itemTwo, j) in itemOne.list" :key="j" >
                                                        <div v-if="itemTwo.isFile != 1">
                                                            <div class="arrowSlide" @click="checkSecondItem(i, j,itemTwo)">
                                                                <div class="arrowTextBox">
                                                                    <div class="arrowAddIcon" :class="{'arrowCutIcon':itemTwo.arrow}"></div>
                                                                    <p class="arrowBoxText">{{itemTwo.title}}</p>
                                                                    <!-- <p v-if="itemTwo.isFile == undefined">dassdda</p> -->
                                                                </div>
                                                            </div>
                                                            <div v-show="isOpenItem[i] && isOpenItem[i].child[j]">
                                                                <ul>
                                                                    <li v-for="(itemThree,k) in itemTwo.list" :key="k">
                                                                        <div v-if="itemThree.type == 1">
                                                                            <div class="orderSlide" @click="linkJump(itemThree.isFree,itemThree.type,itemThree.id,itemThree.obId)">
                                                                                <div class="orderTextBox">
                                                                                    <div class="orderBoxIcon orderVideoIcon">
                                                                                        <img src="../assets/img/loginIcon/class-play-icon.png" alt="">
                                                                                    </div>
                                                                                    <p class="orderBoxText">
                                                                                        <span>[视频]</span>
                                                                                        {{itemThree.title}}
                                                                                    </p>
                                                                                </div>
                                                                                <div class="orderTime">
                                                                                    {{itemThree.duration}}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="itemThree.type == 4">
                                                                            <div class="orderSlide" @click="linkJump(itemThree.isFree,itemThree.type,itemThree.id,itemThree.obId)">
                                                                                <div class="orderTextBox">
                                                                                    <div class="orderBoxIcon orderPaperIcon">
                                                                                        <img src="../assets/img/loginIcon/paper-icon.png" alt="">
                                                                                    </div>
                                                                                    <p class="orderBoxText">
                                                                                        <span>[试卷]</span>
                                                                                        {{itemThree.questionCount}}
                                                                                    </p>
                                                                                </div>
                                                                                <div class="orderTime">
                                                                                    {{itemThree.questionCount}}道题
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="itemThree.type == 5">
                                                                            <div class="orderSlide" @click="linkJump(itemThree.isFree,itemThree.type,itemThree.id,itemThree.obId)">
                                                                                <div class="orderTextBox">
                                                                                    <div class="orderBoxIcon orderPaperIcon">
                                                                                        <img src="../assets/img/loginIcon/paper-icon.png" alt="">
                                                                                    </div>
                                                                                    <p class="orderBoxText">
                                                                                        <span>[试卷]</span>
                                                                                        {{itemThree.title}}
                                                                                    </p>
                                                                                </div>
                                                                                <div class="orderTime">
                                                                                    {{itemThree.questionCount}}道题
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div v-if="itemTwo.isFile == 1">
                                                            <div class="oneSlide">
                                                                <div v-if="itemTwo.type == 1">
                                                                    <div class="oneTextBox" @click="linkJump(itemTwo.isFree,itemTwo.type,itemTwo.id,itemTwo.obId)">
                                                                        <div class="fixTextBox">
                                                                            <div class="fixBoxIcon videoPlay">
                                                                                <img src="../assets/img/loginIcon/class-play-icon.png" alt="">
                                                                            </div>
                                                                            <p class="fixBoxText">
                                                                                <span>[视频]</span>
                                                                                {{itemTwo.title}}
                                                                            </p>
                                                                        </div>
                                                                        <div class="fixTime">
                                                                            {{itemTwo.duration}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div v-if="itemTwo.type == 4">
                                                                    <div class="oneTextBox" @click="linkJump(itemTwo.isFree,itemTwo.type,itemTwo.id,itemTwo.obId)">
                                                                        <div class="fixTextBox">
                                                                            <div class="fixBoxIcon paperIcon">
                                                                                <img src="../assets/img/loginIcon/paper-icon.png" alt="">
                                                                            </div>
                                                                            <p class="fixBoxText">
                                                                                <span>[试卷]</span>
                                                                                {{itemTwo.title}}道题
                                                                            </p>
                                                                        </div>
                                                                        <div class="fixTime">
                                                                            {{itemTwo.questionCount}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div v-if="itemTwo.type == 5">
                                                                    <div class="oneTextBox" @click="linkJump(itemTwo.isFree,itemTwo.type,itemTwo.id,itemTwo.obId)">
                                                                        <div class="fixTextBox">
                                                                            <div class="fixBoxIcon paperIcon">
                                                                                <img src="../assets/img/loginIcon/paper-icon.png" alt="">
                                                                            </div>
                                                                            <p class="fixBoxText">
                                                                                <span>[试卷]</span>
                                                                                {{itemTwo.title}}
                                                                            </p>
                                                                        </div>
                                                                        <div class="fixTime">
                                                                            {{itemTwo.questionCount}}道题
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane label="课程简介" name="second" :key="'second'">
                                <div>
                                    {{classText.intro}}
                                </div>
                        </el-tab-pane>
                        <!-- <el-tab-pane label="学员评价" name="third" :key="'third'">
                                <div style="background: pink; display: inline">
                                    tab3内容
                                </div>
                        </el-tab-pane> -->
                    </el-tabs>
                </div>
                <div class="teacherBox">
                    <div class="title">
                        相关课程讲师
                    </div>
                    <div class="head">
                        <div class="icon"></div>
                        <div class="text">
                            <p class="teacherName">{{teacherInfo.name}}</p>
                            <p class="classCount">课程数量：1</p>
                            <p class="learnPeople">学习人次：1134</p>
                        </div>
                    </div>
                    <div class="brief">
                        <p>{{teacherInfo.intro}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import tabOne from '../components/classDetail/detailTabOne'
export default {
    name: 'classDetail',
    components:{
        // tabOne
    },
    data() {
        return {
            //默认第一个选项卡
            activeName: "first",
            id:this.$route.params.id,
            classText: {},
            headerData: [],
            teacherInfo: {},
            teacherName: {},
            activeNames: [0],
            lessonList: [],
            isOpenItem: [],
            like: false,
            key: '',
            isPay: '',
            isBuy: ''
        }
    },
    mounted() {
        this.getClass()
        // this.dianzhan()
        // this.initData();
    },
    metaInfo: {
        title: '课程详情-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    methods:{
        linkJump(freeid,type,id,obId) {
            if(this.isPay == 0 || this.isBuy == 1 || freeid == 0) {
                if(type == 1) {
                    this.$router.push({
                        path: '/video',
                        query: {
                            courseId: this.id,
                            courseContentId: id
                        }
                    });
                } else if(type == 4) {
                    this.$router.push({
                        name: 'paper',
                        params: {
                            obId: obId,
                            courseId: this.id,
                            courseContentId: id,
                            type: 1
                        }
                    });
                } else if(type == 5) {
                    this.$router.push({
                        name: 'practice',
                        params: {
                            obId: obId,
                            courseId: this.id,
                            courseContentId: id,
                            type: 0
                        }
                    });
                }
            } else {
                this.$alert(
                        '"抱歉，该课程需要购买"',
                        {
                            distinguishCancelAndClose: true,
                            confirmButtonText: '确认',
                            showCancelButton: false,
                            type: "warning"
                        }
                    )
                .then(() => {
                    this.$router.push('/ClassList');
                })
            }
        },
        // 折叠
        checkItem(i,item) {
            this.$set(this.isOpenItem[i], 'group', !this.isOpenItem[i].group);
            item.group =!item.group;

        },
        checkSecondItem(firstIndex, secondIndex,item) {//展开或折叠第二层面板
            this.$set(this.isOpenItem[firstIndex].child, secondIndex, !this.isOpenItem[firstIndex].child[secondIndex]);
            item.arrow =!item.arrow;
            // firstIndex =!firstIndex
            // console.log(firstIndex,"第二")
        },
        // 加入购物车
        addCart(id) {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/cart/add.html',
                data: {
                    courseId: id
                }
            }).then((res) => {
                if(res.data.code == 1000) {
                    this.$message.warning(res.data.msg);
                } else if(res.data.code == 1503){
                    this.$message.warning(res.data.msg);
                } else {
                    this.$message.success("已添加至购物车");
                    this.$store.commit('saveCartCount',res.data.result.count);
                }
            })
        },
        // 立即购买
        buy(id) {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/cart/add.html',
                data: {
                    courseId: id
                }
            }).then((res) => {
                if(res.data.code == 1000) {
                    this.$message.warning(res.data.msg);
                } else if(res.data.code == 1503) {
                    this.$store.commit('saveCartCount',res.data.result.count);
                    this.$router.push({
                        path: '/cart'
                    })
                } else {
                    this.$message.success("已添加至购物车");
                    this.$router.push({
                        path: '/cart'
                    })
                }
            })
        },
        // 获取页面
        getClass() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/course/detail.html',
                data: {
                    courseId: this.id
                }
            }).then((res) => {
                // this.headerData = res.data.result.contents
                this.classText = res.data.result.info;
                this.isPay = res.data.result.info.isPay;
                this.isBuy = res.data.result.info.isBuy;
                this.teacherInfo = res.data.result.teacher;
                this.lessonList = res.data.result.contents;
                for(let i = 0;i<this.lessonList.length;i++) {
                    if(this.lessonList[i].length != undefined) {
                        for(let k = 0;k<this.lessonList[i].list.length;k++) {
                            // this.$set(this.lessonList[i].list[k].list, 'arrow', false);
                            if(this.lessonList[i].list[k] != undefined) {
                                this.$set(this.lessonList[i].list[k], 'arrow', false);
                            }
                        }
                    }
                    
                }
                console.log(this.lessonList,"遍历")
                this.initData()
                
            })
        },
        // 数据校验
        initData() {
            let len = this.lessonList.length;
            
            for (let i = 0; i < len; i++) {
                this.isOpenItem.push([]);
                this.$set(this.lessonList[i], 'group', false);
                this.$set(this.isOpenItem[i], 'group', false);
                // this.$set(this.isOpenItem[i],)
                this.$set(this.isOpenItem[i], 'child', []);
                // for (let j in this.lessonList[j]) {
                //     // console.log(j)
                //     console.log(this.lessonList[j].list,"数据")
                //     // this.$set(this.lessonList.list[j], 'group', false);
                // }
            }
            console.log(this.lessonList,"数据")
        },
        // 点赞
        dianzhan(classText) {
            classText.isFavorite=!classText.isFavorite;
            
            if(classText.isFavorite == false) {
                this.axios({
                    method: 'POST',
                    url: '/api/v2/yun/course/favorite/opt.html',
                    data: {
                        courseId: this.id,
                        opt: 1
                    }
                }).then(() => {
                })
            } else if(classText.isFavorite == true) {
                this.axios({
                    method: 'POST',
                    url: '/api/v2/yun/course/favorite/opt.html',
                    data: {
                        courseId: this.id,
                        opt: 0
                    }
                }).then(() => {
                })
            }
        }
    }
}
</script>
<style lang="scss">
    .classDetail {
        margin-top: 70px;
        .headBox {
            padding-bottom: 20px;
            box-sizing: border-box;
            width: 100%;
            min-width: 1200px;
            // height: 420px;
            background: #444444;
            .headTop {
                margin: 0 auto;
                width: 1200px;
                .crumbs {
                    font-size: 14px;
                    color: #FFFFFF;
                    font-family: Microsoft YaHei;
                    line-height: 50px;
                    i {
                        display: inline-block;
                        margin-right: 5px;
                        width: 14px;
                        height: 14px;
                    }
                    .color {
                        color: #FF5A74;
                    }
                }
                .classBox {
                    display: flex;
                    justify-content: space-between;
                    .videoBox {
                        width: 560px;
                        height: 310px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .describeBox {
                        padding-left: 30px;
                        padding-top: 30px;
                        box-sizing: border-box;
                        width: 615px;
                        height: 310px;
                        background: rgba($color: #ffffff, $alpha: 0.1);
                        .title {
                            margin-bottom: 21px;
                            padding-bottom: 10px;
                            width: 555px;
                            border-bottom: 1px solid #666666;
                            p {
                                font-size: 20px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #FFFFFF;
                            }
                        }
                        .people {
                            display: flex;
                            align-items: center;
                            margin-bottom: 11px;
                            i {
                                margin-right: 10px;
                                display: inline-block;
                                width: 21px;
                                height: 13px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            p {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #BBBBBB;
                            }
                        }
                        .teacher {
                            margin-bottom: 15px;
                            p {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #BBBBBB;
                                line-height: 30px;
                            }
                        }
                        .price {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #BBBBBB;
                            margin-bottom: 25px;
                            .discount {
                                font-size: 24px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #FF5A74;
                            }
                            .orignPrice {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                text-decoration: line-through;
                                color: #BBBBBB;
                            }
                        }
                        .buyBox {
                            display: flex;
                            align-items: center;
                            .flexBox {
                                display: flex;
                                width: 510px;
                                .addCart {
                                    margin-right: 20px;
                                    width: 110px;
                                    height: 40px;
                                    line-height: 40px;
                                    background: #FF5A74;
                                    border-radius: 3px;
                                    color: #ffffff;
                                    text-align: center;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    cursor: pointer;
                                }
                                .buy {
                                    width: 110px;
                                    height: 40px;
                                    // margin-right: 288px;
                                    line-height: 40px;
                                    background: #FF5A74;
                                    border-radius: 3px;
                                    color: #ffffff;
                                    text-align: center;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    cursor: pointer;
                                }
                                .infor {
                                    width: 110px;
                                    height: 40px;
                                    // margin-right: 288px;
                                    line-height: 40px;
                                    background: #FF5A74;
                                    border-radius: 3px;
                                    color: #ffffff;
                                    text-align: center;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    cursor: pointer;
                                }
                            }
                            
                            .like {
                                cursor: pointer;
                                .nonelike {
                                    width: 26px;
                                    height: 26px;
                                    background: url("../assets/img/loginIcon/nodianzan-icon.png") no-repeat;
                                }
                                .islike {
                                    width: 26px;
                                    height: 26px;
                                    background: url("../assets/img/loginIcon/dianzan-icon.png") no-repeat;
                                }
                            }
                        }
                    }
                }
            }
        }
        .contentBox {
            width: 100%;
            padding-top: 30px;
            padding-bottom: 30px;
            box-sizing: border-box;
            min-width: 1200px;
            background: #EEEEEE;
            .detailBox {
                margin: 0 auto;
                width: 1200px;
                display: flex;
                justify-content: space-between;
                .tabBox {
                    width: 860px;
                    // height: 803px;
                    background: #FFFFFF;
                    padding-left: 30px;
                    padding-top: 20px;
                    box-sizing: border-box;
                    .el-tabs__header {
                        width: 800px;
                        .el-tabs__header {
                            padding: none;
                            padding-right: 70px;
                        }
                        .el-tabs__item {
                            font-size: 16px;
                            &:hover {
                                color: #FF5A74;
                            }
                        }
                        .el-tabs__item.is-active {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FF5A74;
                        }
                        .el-tabs__active-bar {
                            width: 64px;
                            background-color: #FF5A74;
                        }
                    }
                    .el-tabs__content {
                        overflow: visible;
                    }
                    .listBox {
                        .listUL {
                            .listLi {
                                margin-bottom: 20px;
                                .listSlide {
                                    position: relative;
                                    padding-left: 42px;
                                    box-sizing: border-box;
                                    width: 800px;
                                    height: 50px;
                                    line-height: 50px;
                                    background: #F0F3F5;
                                    cursor: pointer;
                                    .book-icon {
                                        position: absolute;
                                        top: 18px;
                                        left: 13px;
                                        width: 12px;
                                        height: 14px;
                                        img {
                                            display: block;
                                        }
                                    }
                                    .arrow-down-icon {
                                        position: absolute;
                                        top: 17px;
                                        right: 19px;
                                        width: 12px;
                                        height: 14px;
                                        background: url("../assets/img/loginIcon/arrow-down-icon.png") no-repeat;
                                    }
                                    .arrow-up-icon {
                                        position: absolute;
                                        top: 17px;
                                        right: 19px;
                                        width: 12px;
                                        height: 14px;
                                        background: url("../assets/img/loginIcon/arrow-top-icon.png") no-repeat;
                                    }
                                }
                                .topText {
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                }
                                .slideBox {
                                    .slideUl {
                                        margin-left: 20px;
                                        width: 760px;
                                        .slideLi {
                                            padding-left: 20px;
                                            box-sizing: border-box;
                                            width: 760px;
                                            // height: 50px;
                                            line-height: 50px;
                                            // background: blue;
                                            margin-bottom: 22px;
                                            // background: #FAFAFA;
                                            .arrowSlide {
                                                .arrowTextBox {
                                                    display: flex;
                                                    align-items: center;
                                                    .arrowAddIcon {
                                                        margin-right: 12px;
                                                        width: 16px;
                                                        height: 16px;
                                                        background: url("../assets/img/loginIcon/add-icon.png") no-repeat;
                                                    }
                                                    .arrowCutIcon {
                                                        width: 16px;
                                                        height: 16px;
                                                        background: url("../assets/img/loginIcon/cut-icon.png") no-repeat;
                                                    }
                                                    .arrowBoxText {
                                                        font-size: 14px;
                                                        font-family: Microsoft YaHei;
                                                        font-weight: 400;
                                                        color: #FF5A74;
                                                        cursor: pointer;
                                                    }
                                                }
                                            }
                                            .oneSlide {
                                                .oneTextBox {
                                                    display: flex;
                                                    justify-content: space-between;
                                                    padding-left: 28px;
                                                    padding-right: 28px;
                                                    box-sizing: border-box;
                                                    background: #FAFAFA;
                                                    align-items: center;
                                                    cursor: pointer;
                                                    .fixTextBox {
                                                        display: flex;
                                                        align-items: center;
                                                        .fixBoxIcon {
                                                            margin-right: 10px;
                                                            img {
                                                                display: block;
                                                            }
                                                        }
                                                        .videoPlay {
                                                            width: 14px;
                                                            height: 14px;
                                                        }
                                                        .paperIcon {
                                                            width: 15px;
                                                            height: 15px;
                                                        }
                                                        .fixBoxText {
                                                            font-size: 14px;
                                                            font-family: Microsoft YaHei;
                                                            font-weight: 400;
                                                            color: #333333;
                                                        }
                                                    }
                                                }
                                                .fixTime {
                                                    font-size: 12px;
                                                    font-family: Microsoft YaHei;
                                                    font-weight: 400;
                                                    color: #999999;
                                                }
                                                
                                            }
                                            .orderSlide {
                                                margin-bottom: 12px;
                                                padding-left: 28px;
                                                padding-right: 28px;
                                                box-sizing: border-box;
                                                display: flex;
                                                justify-content: space-between;
                                                background: #FAFAFA;
                                                .orderTextBox {
                                                    display: flex;
                                                    align-items: center;
                                                    .orderBoxIcon {
                                                        margin-right: 10px;
                                                        img {
                                                            display: block;
                                                        }
                                                    }
                                                    .orderVideoIcon {
                                                        width: 14px;
                                                        height: 14px;
                                                    }
                                                    .orderPaperIcon {
                                                        width: 15px;
                                                        height: 15px;
                                                    }
                                                    .orderBoxText {
                                                        font-size: 14px;
                                                        font-family: Microsoft YaHei;
                                                        font-weight: 400;
                                                        color: #333333;
                                                        cursor: pointer;
                                                        &:hover {
                                                            color: #FF5A74;
                                                        }
                                                    }
                                                }
                                                .orderTime {
                                                    font-size: 12px;
                                                    font-family: Microsoft YaHei;
                                                    font-weight: 400;
                                                    color: #999999;
                                                }
                                            }
                                            .slideTwo {
                                                margin-left: 24px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .teacherBox {
                    width: 319px;
                    height: 803px;
                    padding-left: 12px;
                    padding-top: 30px;
                    box-sizing: border-box;
                    background: #FFFFFF;
                    .title {
                        width: 279px;
                        margin-bottom: 20px;
                        padding-bottom: 9px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        border-bottom: 2px solid #EEEEEE;
                    }
                    .head {
                        display: flex;
                        width: 279px;
                        margin-bottom: 20px;
                        padding-bottom: 11px;
                        border-bottom: 2px solid #EEEEEE;
                        .icon {
                            margin-right: 20px;
                            width: 105px;
                            height: 105px;
                            background: #E5E5E5;
                            border-radius: 50%;
                        }
                        .text {
                            padding-top: 10px;
                            .teacherName {
                                margin-bottom: 10px;
                                font-size: 20px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                            }
                            .classCount {
                                margin-bottom: 6px;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                            }
                            .learnPeople {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                            }
                        }
                    }
                    .brief {
                        width: 279px;
                        p {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                }
            }
            
        }
    }
</style>